// Included bh sanjana SOP-40 to create scss style page for import asset csv
.App {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }
  label {
    width: 350px;
    box-sizing: border-box;
  }

//   End of SOP-40