// .flip-box {
//     background-color: 'transparent';
//     width: 700px;
//     height: 350px;
//     border: 1px solid #f1f1f1;
//     perspective: 1000px;
//     border-radius: '50rem';
//   }
  
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: '50rem'
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
    border-radius: '50rem'
  }
  
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: '50rem'
  }
  
  .flip-box-front {
    background-color: white;
    color: black;
    border-radius: '50rem'
  }
  
  .flip-box-back {
    background-color: #fcffd3;
    color: white;
    transform: rotateY(180deg);
    border-radius: '50rem'
  }