
//SOP-93 Included By PCS [to include Rubik font]
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
//EOF SOP-93 Included By PCS 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Rubik',
   
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import './asset/scss/_themes-vars.module.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Rubik',
    
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* @font-face {
  font-family: "EpilogueVariableFont";
  src: local("EpilogueVariableFont"),
    url("./asset/fonts/Epilogue/Epilogue-VariableFont_wght.ttf") format("truetype");
} */

// @font-face {
//   font-family: "InterVariableFont";
//   src: local("InterVariableFont"),
//     url("./asset/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
// }



@font-face {
  font-family: "PoppinsVariableFont";
  src: local("poppinsVariableFont"),
    url("./asset/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customInput_padding > .MuiInputBase-root{
    padding: 5px 10px;

}

.customInput_padding > .MuiInputBase-root::before{
    border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInputBase-root::after{
    border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::before{
    border-bottom: 2px solid $errorMain !important;
}

.customInput_padding > .MuiInput-root.Mui-error::after{
    border-bottom: 2px solid $errorMain !important;
}

.swal2-container {
  z-index: 99999;
}
.swal2-confirm, .swal2-cancel {
  outline: none !important;
  box-shadow: none !important;
}

.backbtn{
  padding: "5px";
  background-color: green;
}


.formCards {
  // background-color: white;
  //padding-bottom: 20px;
  // height: 70vh;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  .viewpatientForm {
    margin-top: 40px;
    padding: 0 40px;
    .viewHeading {
      color: #888;
    }
    .viewDesc {
      font-weight: 600;
    }
    .information {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 50px;
      .cancelbtn {
        padding: 0 35px;
        border-radius: 5px !important;
        color: $buttonColor;
        height: 3rem;
        background-color: #28389110;
      }
      .createbtn {
        color: #ffffffff; /* white */
        background: $buttonColor;
        outline: none;
        border: none;
        box-shadow: none;
        border-radius: 5px !important;
        padding: 0 35px;
        height: 3rem;
      }
      .editbtn {
        color: #ffffffff; /* white */
        background: $buttonColor;
        outline: none;
        border: none;
        box-shadow: none;
        border-radius: 5px !important;
        padding: 0 45px;
        height: 3rem;
      }
    }
  }
}

.bgco{
  color:"bgco"
}

.adjust_icons{
  color: "#4BBED3";
font-size: "20px";
}
@media only screen and (max-width: 500px) {
  .referalDoctorForm {
    margin-top: 0 !important;
    padding: 0 10px !important;
    .information {
      margin-top: 20px !important;
    }
  }
}
