// Included by sanjana SOP-25 to sete css style for request an asset page
.flip-box {
    background-color: transparent;
    width: 200px;
    height: 200px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
  }
  
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    background-color: white;
    color: black;
  }
  
  .flip-box-back {
    background-color: #D7E3FA;
    color: white;
    transform: rotateY(180deg);
  }
// End of SOP-25