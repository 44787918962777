// SOP-97 Included by PCS [Ticketing Masters- Closure Information]

.switchToggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switchToggle input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .sliderToggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .sliderToggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .sliderToggle {
    background-color: #4BBED3;
  }
  
  input:focus + .sliderToggle {
    box-shadow: 0 0 1px #4BBED3;
  }
  
  input:checked + .sliderToggle:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliderToggles */
  .sliderToggle.round {
    border-radius: 34px;
  }
  
  .sliderToggle.round:before {
    border-radius: 50%;
  }
  
// EOF SOP-97 Included by PCS [Ticketing Masters- Closure Information]