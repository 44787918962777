// Included by sanjana SOP-56 to set sccs style for hierarchy grid page

  /* src/HierarchyGrid.scss */
  .grid-container {
    
    box-shadow:rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius:3px;
    border:1px solid #757ce8;
    min-height: 300px;
    padding: 10px;
    margin: 10px;
    width: 250px;
    background-color: #f8f9fa;
    position: relative;
  }
  
  .grid-row {
    height: 75px;
    padding: 8px;
    margin: 4px 0;
    // border: 1px solid gray;
    background-color: #757ce8;
    position: relative;
    margin-bottom: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    // box-shadow:rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius:3px;
    color: white;
    border:1px solid #757ce8;
  }

  .grid-container-DD {
    
    box-shadow:rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius:3px;
    border:1px solid #757ce8;
    min-height: 300px;
    padding: 10px;
    margin: 10px;
    width: 250px;
    background-color: #757ce8;
    position: relative;
  }

  .grid-row-DD {
    height: 75px;
    padding: 8px;
    margin: 4px 0;
    // border: 1px solid gray;
    background-color:white;
    position: relative;
    margin-bottom: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    // box-shadow:rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius:3px;
    border:1px solid #757ce8;
  }
  
  .placeholder-indicator {
    position: absolute;
    height: 4px;
    background-color: blue;
    width: calc(100% - 16px);
    left: 8px;
    top: -2px;
  }
  
  // End of SOP-56