@import '../../../../asset/scss/themes-vars.module';

.side-menu-icon-list {
    // svg {
    //     width: '3rem';
    //     height: '3rem' !important;
    // }
}

// .active-side-menu::before {
//     content: '';
//     position: absolute;
//     background: $primary;
//     border-radius: 0px 6px 6px 0px;
//     width: 6px;
//     height: 100%;
//     bottom: 0;
//     left: 0px;
// }

.profile-name {
    color: rgba(0, 0, 0, 0.87);
    font-size: 16px;
    letter-spacing: 0.15px;
    font-weight: 400;
    margin: 0px;
    line-height: 30px;
}

.profile-mail {
    margin: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: rgba(0, 0, 0, 0.6);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.appbar-backgorund{
    // background-image:url("../../../../asset/images/appbarbg.png") ;
    // background-image:url("../../../../asset/images/HIS\ Web\ 2.0\ band.jpg") ;
        // background-image:url("../../../../asset/images/HIS\ Web\ 2.0\ band.jpg") ;


}