@import "../../../asset/scss/themes-vars.module";

.showIcon {
  visibility: hidden !important;
}
.tableheading {
  color: $tableHeadText;
  padding-left: 15px;
}
.filterimage {
  width: 8px;
  margin-left: 5px;
}
.MuiTableSortLabel-icon {
  display: none;
}
.css-1d1tm32-MuiTableRow-root.MuiTableRow-hover:hover {
  .showIcon {
    visibility: visible !important;
  }
}
.table-container::-webkit-scrollbar {
  width: 5px;
}
.table-container::-webkit-scrollbar-thumb {
  background-color: rgb(203, 200, 200);
  // outline: 1px solid slategrey;
  border-radius: 16px;
  margin-top: 10px;
}
.bold {
  font-weight: 700;
}
.booknowbtn {
  color: #ffffffff; /* white */
  background: $buttonColor;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 5px !important;
  padding: 10px 35px;
&:hover{
  background: $buttonColor;

}

}

.moreAction{
  // padding:20px 10px;
  font-weight: 700;
  &:hover{
    background: #ecf5fa;
  
  }
}
